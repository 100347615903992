@font-face {
  font-family: "JetBrains Mono";
  src: url("/fonts/JetBrainsMono-Regular.eot") format("embedded-opentype"),
    url("/fonts/JetBrainsMono-Regular.woff2") format("woff2"),
    url("/fonts/JetBrainsMono-Regular.woff") format("woff"),
    url("/fonts/JetBrainsMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
#root {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
pre,
code,
span,
footer {
  font-family: "JetBrains Mono", monospace;
  margin: 0;
}

p,
pre,
span,
footer {
  font-size: 12px;
}

.main {
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 700px;
  margin: auto;
}

.title {
  font-size: 16px;
}

.heading {
  font-size: 14px;
}

.inline {
  display: inline;
}

.underline {
  text-decoration: underline; 
}

.bold {
  font-weight: 700;
}

.bottom-divider {
  border-bottom: 1px solid black;
}

.header-section {
  margin-bottom: 32px;
}

.code-section {
  margin-bottom: 32px;
}

.docs-section {
  flex: 0.75;
  padding-bottom: 16;
  padding-top: 16;
}

.text-opac {
  opacity: 0.75;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.5;
}

footer {
  margin-top: 32px;
  margin-bottom: 32px;
}

@media screen and (max-width: 600px) {
  pre {
    font-size: 9px;
  }
}

@media (prefers-color-scheme: dark) {
  html,
  body,
  #root {
    background-color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  pre,
  code,
  span,
  footer {
    color: white;
  }

  .bottom-divider {
    padding-bottom: 16px;
    border-bottom: 1px solid white;
  }

  .text-opac {
    opacity: 0.50;
  }  
}
